
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  nextTick
} from 'vue'
import OrderTable, { TableColumnItem } from '@/components/filter-table/Index.vue'
import { GetOrderList } from '@/apis/order'
import { requestSecondList } from '@/apis/second'
import requestMiddle, { RequestInter } from '@/utils/request'
import { IndexMessage } from '@/model/orderModel'
import { useRouter } from 'vue-router'
// import { parseInt } from 'lodash'
interface DataInter{
  tableData: IndexMessage<string>[]
  exportData: IndexMessage<string>[]
  commission: string
  tableColumn: TableColumnItem[]
  isLoading: boolean
  month: string
  isSecondary: boolean
  listTotal: number
  agentName: string
  title: string
  agentId: string
  requestSList: (option?: GetOrderList, callback?: () => void, failCb?: () => void) => void
}
export default defineComponent({
  components: {
    // PlateTitle
    OrderTable
  },
  setup() {
    const orderTableRef = ref(null)
    const router = useRouter()
    const data: DataInter = reactive({
      exportData: [],
      tableData: [],
      commission: '',
      listTotal: 0,
      month: '',
      isSecondary: false,
      isLoading: true,
      agentName: '',
      title: '',
      agentId: '',
      tableColumn: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'create_time',
          label: '加入时间',
          width: '200'
        },
        {
          prop: 'agent_name',
          label: '二级代理',
          width: '220'
        },
        {
          prop: 'mobile',
          label: '手机号',
          width: '220'
        },
        {
          prop: 'sales_volume',
          label: '累计销售额',
          width: '150'
        },
        {
          prop: 'order_num',
          label: '累计订单',
          width: '150'
        },
        {
          prop: 'commission_amount',
          label: '累计佣金'
        },
        // {
        //   prop: 'commission_amount',
        //   label: '状态',
        //   width: '180'
        // },
        {
          prop: 'option',
          label: '操作',
          width: '180',
          hascope: true
        }
      ],
      requestSList: (option, successCb?, failCb?) => {
        const newOption = {
          agent_id: data.agentId
        }
        nextTick(async() => {
          if (orderTableRef.value) {
            const { startTime, endTime, currentPage, pageSize } = orderTableRef.value as any
            const filterData = {
              start_time: startTime,
              end_time: endTime,
              page: currentPage,
              size: pageSize,
              agent_name: data.title
            }
            Object.assign(newOption, filterData, option)
          }
          const dataType = option?.export === 1 ? 'exportData' : 'tableData'
          const requestData = await requestMiddle({
            requestMethodName: requestSecondList,
            option: newOption
          } as RequestInter)
          try {
            let i = 1
            data[dataType] = requestData.data.map((item: IndexMessage<string | number>) => {
              item.option = '详情'
              item.serial = i++
              return item
            })
            console.log(data[dataType])
            data.listTotal = requestData.total
            successCb && successCb()
          } catch (error) {
            console.log(error)
            failCb && failCb()
          }
          data.isLoading = false
        })
      }
    })

    const orderMethods = reactive({
      columnClick: (row: Array<IndexMessage<string> | string>) => {
        if (row[0] === 'option') {
          router.push({
            name: 'Basicinfo',
            params: { id: (row[1] as IndexMessage<string>).id, name: (row[1] as IndexMessage<string>).agent_name }
          })
        }
      },
      handleFilter: () => {
        data.requestSList({
          search_key: data.title
        })
      },
      handleClear: () => {
        data.title = ''
      }
    })

    onBeforeMount(() => {
      data.requestSList()
    })

    return {
      ...toRefs(data),
      ...toRefs(orderMethods),
      orderTableRef
    }
  }
})
