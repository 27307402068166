<template>
  <div
    class="order-container fami-container"
  >
    <template v-if="!isLoading">
      <div
        class="top"
        v-if="!isSecondary"
      >
        <div
          class="button-group"
        >
          <router-link
            class="spacing-link"
            to="/secondary/add"
          >
            <button
              class="green-btn"
            >
              添加代理
            </button>
          </router-link>
          <router-link
            class="spacing-link"
            to="/secondary/batchadd"
          >
            <button
              class="green-border-btn"
            >
              批量导入
            </button>
          </router-link>
        </div>
      </div>
      <div
        class="top"
        v-else
      >
        销售员 ： {{ agentName }}
      </div>
      <OrderTable
        :tableData="tableData"
        :tableColumn="tableColumn"
        :listTotal="listTotal"
        :exportData="exportData"
        @clearFilter="handleClear"
        @getList="requestSList"
        @columnClick="columnClick"
        ref="orderTableRef"
        :defaultSort="{prop: 'flow_time', order: 'descending'}"
      >
        <el-input
          v-model="title"
          placeholder="输入二级代理名称"
          style="max-width: 300px"
          class="filter-item filter-item-title"
          @keyup.enter="handleFilter"
        />
      </OrderTable>
    </template>
  </div>
</template>

<script lang='ts'>
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  ref,
  nextTick
} from 'vue'
import OrderTable, { TableColumnItem } from '@/components/filter-table/Index.vue'
import { GetOrderList } from '@/apis/order'
import { requestSecondList } from '@/apis/second'
import requestMiddle, { RequestInter } from '@/utils/request'
import { IndexMessage } from '@/model/orderModel'
import { useRouter } from 'vue-router'
// import { parseInt } from 'lodash'
interface DataInter{
  tableData: IndexMessage<string>[]
  exportData: IndexMessage<string>[]
  commission: string
  tableColumn: TableColumnItem[]
  isLoading: boolean
  month: string
  isSecondary: boolean
  listTotal: number
  agentName: string
  title: string
  agentId: string
  requestSList: (option?: GetOrderList, callback?: () => void, failCb?: () => void) => void
}
export default defineComponent({
  components: {
    // PlateTitle
    OrderTable
  },
  setup() {
    const orderTableRef = ref(null)
    const router = useRouter()
    const data: DataInter = reactive({
      exportData: [],
      tableData: [],
      commission: '',
      listTotal: 0,
      month: '',
      isSecondary: false,
      isLoading: true,
      agentName: '',
      title: '',
      agentId: '',
      tableColumn: [
        {
          prop: 'serial',
          label: '序号',
          width: '80'
        },
        {
          prop: 'create_time',
          label: '加入时间',
          width: '200'
        },
        {
          prop: 'agent_name',
          label: '二级代理',
          width: '220'
        },
        {
          prop: 'mobile',
          label: '手机号',
          width: '220'
        },
        {
          prop: 'sales_volume',
          label: '累计销售额',
          width: '150'
        },
        {
          prop: 'order_num',
          label: '累计订单',
          width: '150'
        },
        {
          prop: 'commission_amount',
          label: '累计佣金'
        },
        // {
        //   prop: 'commission_amount',
        //   label: '状态',
        //   width: '180'
        // },
        {
          prop: 'option',
          label: '操作',
          width: '180',
          hascope: true
        }
      ],
      requestSList: (option, successCb?, failCb?) => {
        const newOption = {
          agent_id: data.agentId
        }
        nextTick(async() => {
          if (orderTableRef.value) {
            const { startTime, endTime, currentPage, pageSize } = orderTableRef.value as any
            const filterData = {
              start_time: startTime,
              end_time: endTime,
              page: currentPage,
              size: pageSize,
              agent_name: data.title
            }
            Object.assign(newOption, filterData, option)
          }
          const dataType = option?.export === 1 ? 'exportData' : 'tableData'
          const requestData = await requestMiddle({
            requestMethodName: requestSecondList,
            option: newOption
          } as RequestInter)
          try {
            let i = 1
            data[dataType] = requestData.data.map((item: IndexMessage<string | number>) => {
              item.option = '详情'
              item.serial = i++
              return item
            })
            console.log(data[dataType])
            data.listTotal = requestData.total
            successCb && successCb()
          } catch (error) {
            console.log(error)
            failCb && failCb()
          }
          data.isLoading = false
        })
      }
    })

    const orderMethods = reactive({
      columnClick: (row: Array<IndexMessage<string> | string>) => {
        if (row[0] === 'option') {
          router.push({
            name: 'Basicinfo',
            params: { id: (row[1] as IndexMessage<string>).id, name: (row[1] as IndexMessage<string>).agent_name }
          })
        }
      },
      handleFilter: () => {
        data.requestSList({
          search_key: data.title
        })
      },
      handleClear: () => {
        data.title = ''
      }
    })

    onBeforeMount(() => {
      data.requestSList()
    })

    return {
      ...toRefs(data),
      ...toRefs(orderMethods),
      orderTableRef
    }
  }
})
</script>
<style lang="scss" scoped>
.filter-item-title{
  margin: 0px;
  max-width: 500px;
}
.order-container{
  .top{
    padding:0 0 30px ;
    p{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
    }
    span{
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .button-group{
    padding-bottom: 30px;
    display: flex;
  }
  .el-pagination{
    display: flex;
    justify-content: flex-end;
    margin-top:50px;
  }
  .spacing-link button{
    margin: 0 5px;
  }
}
.green-border-btn,.green-btn{
  padding: 0 1.4em;
}
@media screen and (max-width:768px) {
  .filter-item-title{
    margin: 0;
    max-width: 500px;
  }

}
@media screen and (max-width: 414px) {
  .order-container .top .filter-group{
    flex-direction: column;
    align-items: flex-start;
  }
  .filter-item-title{
    margin-top: 10px;
  }
  .green-border-btn, .green-btn{
      font-size: 14px;
    }
}

</style>
